@import "~@lexdania/components/build/lib/styles/colors/_ministerialtidende-colors.scss";

@media (max-width: 450px) {
  .px-xs-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

body ::selection {
  color: $primary-color-font;
  background-color: $primary-color;
}

body ::-moz-selection {
  color: $primary-color-font;
  background-color: $primary-color;
}

.main-content-body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;

  // Remove the bottom-border on header, because the search box should stick to the header
  // This style is a part of the header component from LDC, so there is no other way to do this without causing problems
  // on the other sites eg. Statstidende.dk
  // header {
  //   .header-nav {
  //     border-bottom: none;
  //   }
  // }
}

.container.content-area {
  .alert {
    margin-top: 10px;
    border: none;

    button {
      border: none;
      background-color: transparent;
      margin-right: 10px;
      cursor: pointer;
    }

    h5 {
      margin: 10px 0;
    }
  }
}
